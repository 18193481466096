



















import { Component, Prop, Vue } from "vue-property-decorator";
import { ProductPriceBase } from "../../@types/product-price.type";

/** creates a price for a new product */
@Component
export default class AddPriceToNewProduct extends Vue {
  @Prop({ default: false, type: Boolean })
  private isDefaultPriceSet: boolean;
  @Prop({ default: false })
  private isEditMode: boolean;

  item: Nullable<ProductPriceBase> = null;

  isVisible = false;

  get cannotSetDefaultPrice() {
    return this.isDefaultPriceSet && (this.item?.is_default == false || this.item?.is_default == 0);
  }

  show(payload: ProductPriceBase) {
    this.item = { ...payload };
    if (typeof this.item.is_default !== "boolean") this.item.is_default = payload.is_default === 1;
    this.isVisible = true;
  }

  onClose() {
    this.isVisible = false;
  }

  async onSubmit() {
    this.isVisible = false;
    if (this.isEditMode) this.$emit("onPriceUpdatedOnNewProduct", this.item);
    else this.$emit("onPricesAddedToNewProduct", this.item);
    this.clear();
  }

  clear() {
    this.item = null;
  }
}
