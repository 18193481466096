

























































import { Product, ProductPrice } from "@/modules/dishes/@types/product.type";
import { Component, Vue } from "vue-property-decorator";
import { ProductsManagementService } from "../api/products-management.service";
import { ProductPriceBase } from "../@types/product-price.type";

import TextEditor from "@/components/ui/TextEditor.vue";
import ReadMore from "@/components/ui/ReadMore.vue";
import EditPrices from "../components/edit/EditPrices.vue";

@Component({ components: { TextEditor, ReadMore, EditPrices } })
export default class extends Vue {
  id: Nullable<string> = null;
  isNew = false;
  item: Nullable<Product> = null;

  isImgEditing = false;
  tempImgLink: Nullable<string> = null;

  isDescEditing = false;
  tempDesc: Nullable<string> = null;

  prices: ProductPriceBase[] = [];

  groups: any[] = [];

  async mounted() {
    this.init();

    if (!this.isNew && this.id) this.getProduct();

    this.getGroups();
  }

  init() {
    this.id = this.$route.query.productId as string;
    this.isNew = this.$route.query.isNew == "true";

    if (this.isNew) {
      this.item = {
        category_id: (null as unknown) as number,
        category_name: (null as unknown) as string,
        description: undefined,
        id: (null as unknown) as number,
        image_link: (null as unknown) as string,
        product_name: (null as unknown) as string,
        prices: [],
        overallUserSelectionCount: 0,
      };
    }
  }

  async getProduct() {
    this.item = await ProductsManagementService.getProductById(this.id as string);
    this.tempDesc = this.item?.description as string;
    await this.getPrices();
  }

  async getPrices() {
    this.prices = await ProductsManagementService.getProductsPrices(this.id as string);
  }

  async getGroups() {
    this.groups = await ProductsManagementService.getGroups();
  }

  get productId() {
    return this.item?.id;
  }

  onPricesUpdated() {
    this.getPrices();
  }

  onSaveImage() {
    if (this.item) this.item.image_link = this.tempImgLink as string;
    this.isImgEditing = false;
  }

  onSaveDesc() {
    if (this.item) this.item.description = this.tempDesc as string;
    this.isDescEditing = false;
  }

  async onSaveProduct() {
    if (this.isNew && this.item) {
      this.item.prices = (this.prices as unknown) as ProductPrice[];
      await ProductsManagementService.createProduct(this.item);
    } else await ProductsManagementService.updateProduct(this.item as Product);

    // this.$router.push({ name: "Management" });
  }
}
